import React, { useEffect } from "react";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import { IconApp } from "../../../IconApp";
import { NavLinkApp } from "../../../NavLinkApp";
import SidebarNavAccordion from "../../../SidebarNavAccordion";
import { useDispatch, useSelector } from "react-redux";
import { startUIShowModulesCount } from "../../../../redux/actions/administrador/ui/ui";

export const Modules_Academic = () => {
    const dispatch = useDispatch();
    const { hasPermission } = useHasPermission();
    const { color_text_menu_accordion } = useSelector((state) => state.config);

    // const { user } = useSelector((state) => state.auth);

    const canShowAccordion = hasPermission([
        "STUDENTS",
        "SCHOOL_GROUPS",
        "CLARIFICATIONS",
        "ADMISSION_STUDENTS",
        "READ_STUDENTS",
        "CAN_REGISTER_NEWS",
        "TEACHERS",
        "PAYROLL_TEACHERS_V2",
        "PAYROLL_TEACHERS_V1",
        "TEACHERS_READONLY",
        "MODULES_TEACHERS",
        "EAD_LIST",
        "ORIGIN_SCHOOLS_REPORT",
    ]);

    useEffect(() => {
        if (canShowAccordion) {
            dispatch(startUIShowModulesCount("Modules_Academic"));
        }
    }, [canShowAccordion]);

    return (
        // Los permisos que agregues en este nivel van a determinar cuando se renderiza el dropdown de control escolar
        <div>
            {canShowAccordion && (
                <SidebarNavAccordion
                    title={"Gestión Académica"}
                    itemLevel={0}
                    color={color_text_menu_accordion}
                    iconLeftClassName="fa-solid fa-solid fa-book fa-xl"
                >
                    {/* Si agregas un nuevo modulo aqui, con un permiso nuevo, asegurate que este en el elemento padre, aqui arribita ⬆️ */}
                    {hasPermission("ADMISSION_STUDENTS") && (
                        <NavLinkApp
                            name="Aspirantes"
                            to="/app/students/admission"
                            icon={
                                <IconApp iconClassName="fas fa-calendar-circle-user c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {(hasPermission("STUDENTS") ||
                        hasPermission("READ_STUDENTS")) && (
                        <NavLinkApp
                            name="Estudiantes"
                            to="/app/students"
                            icon={
                                <IconApp iconClassName="fas fa-user-graduate c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("SCHOOL_GROUPS") && (
                        <NavLinkApp
                            name="Grupos académicos"
                            to="/app/operators/academicgroups"
                            icon={
                                <IconApp iconClassName="fas fa-users c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("EXTRACURRICULAR_SUBJECTS") && (
                        <NavLinkApp
                            name="Materias extracurriculares"
                            to="/app/operators/extracurricular-subjects"
                            icon={
                                <IconApp iconClassName="fas fa-basketball c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("CAN_REGISTER_NEWS") && (
                        <div>
                            <NavLinkApp
                                name="Avisos"
                                to="/app/news"
                                icon={
                                    <IconApp iconClassName="fas fa-bullhorn c-sidebar-nav-icon"></IconApp>
                                }
                            />
                        </div>
                    )}
                    {hasPermission("CLARIFICATIONS") && (
                        <div>
                            <NavLinkApp
                                name="Aclaraciones"
                                to="/app/school/clarifications"
                                icon={
                                    <IconApp iconClassName="fas fa-message-exclamation c-sidebar-nav-icon"></IconApp>
                                }
                            />
                        </div>
                    )}
                    {hasPermission([
                        "TEACHERS",
                        "PAYROLL_TEACHERS_V2",
                        "PAYROLL_TEACHERS_V1",
                        "TEACHERS_READONLY",
                        "MODULES_TEACHERS",
                        "EAD_LIST",
                    ]) && (
                        <SidebarNavAccordion
                            title={"Docentes"}
                            itemLevel={1}
                            color={color_text_menu_accordion}
                            iconLeftClassName="fa-solid fa-school fa-lg"
                        >
                            {hasPermission([
                                "TEACHERS",
                                "TEACHERS_READONLY",
                            ]) && (
                                <NavLinkApp
                                    name="Docentes"
                                    to="/app/teachers/list"
                                    icon={
                                        <IconApp iconClassName="fas fa-chalkboard-teacher c-sidebar-nav-icon"></IconApp>
                                    }
                                />
                            )}

                            {hasPermission("PAYROLL_TEACHERS_V2") && (
                                <>
                                    <NavLinkApp
                                        name="Nóminas docentes"
                                        to="/app/teachers/v2/payroll/list"
                                        icon={
                                            <IconApp iconClassName="fas fa-file-invoice-dollar c-sidebar-nav-icon"></IconApp>
                                        }
                                    />

                                    <NavLinkApp
                                        name="Justificaciones"
                                        to="/app/teachers/v2/justify/list"
                                        icon={
                                            <IconApp iconClassName="fas fa-file-invoice-dollar c-sidebar-nav-icon"></IconApp>
                                        }
                                    />
                                </>
                            )}

                            {hasPermission("MODULES_TEACHERS") && (
                                <>
                                    <NavLinkApp
                                        name="Modulos Docentes"
                                        to="/app/modules/teachers/list"
                                        icon={
                                            <IconApp iconClassName="fas fa-chalkboard-teacher c-sidebar-nav-icon"></IconApp>
                                        }
                                    />
                                    <NavLinkApp
                                        name="Grupo de Docentes"
                                        to="/app/modules/teachers/groups/list"
                                        icon={
                                            <IconApp iconClassName="fas fa-users c-sidebar-nav-icon"></IconApp>
                                        }
                                    />
                                </>
                            )}

                            {hasPermission("PAYROLL_TEACHERS_V1") && (
                                <>
                                    <NavLinkApp
                                        name="Nóminas docentes v1"
                                        to="/app/teachers/payrolls/list"
                                        icon={
                                            <IconApp iconClassName="fas fa-file-invoice-dollar c-sidebar-nav-icon"></IconApp>
                                        }
                                    />
                                </>
                            )}

                            {hasPermission("EAD_LIST") && (
                                <div>
                                    <NavLinkApp
                                        name="EAD"
                                        to="/app/ead/lista"
                                        icon={
                                            <IconApp iconClassName="fas fa-school c-sidebar-nav-icon"></IconApp>
                                        }
                                    />
                                </div>
                            )}
                        </SidebarNavAccordion>
                    )}
                </SidebarNavAccordion>
            )}
        </div>
    );
};
