export const types = {
    //auth
    authLogin: "[auth]:authLogin",
    authRegister: "[auth]:authRegister",
    authRenew: "[auth]:authRenew",
    authLogout: "[auth]:authLogout",
    authCheckingFinish: "[auth]:authCheckingFinish",

    //ui
    uiStartLoading: "[iu]:startLoading",
    uiStopLoading: "[iu]:stopLoading",
    uiSidebar: "[ui]toggleSidebar",
    uiSetEnviromentVersions: "[ui]EnviromentVersions",
    uiShownotification: "[ui]showNotification",
    uiSetNotifications: "[ui]uiSetNotifications",
    uiIncrementShowModulesCount: "[ui]uiIncrementShowModulesCount",

    //Modules
    modulesAll: "[modules]all",
    modulesGroupAll: "[modules]groupAll",
    modulesShow: "[modules]show",
    moduleSetActive: "[modules]active",
    moduleOpenForm: "[modules]openForm",
    moduleCloseForm: "[modules]closeForm",
    moduleUsersWithPermission: "[modules]usersWithPermission",

    //Groups
    groupOpenModalModules: "[group]OpenModalModules",
    groupCloseModalModules: "[group]CloseModalModules",
    groupOpenModalTeachers: "[group]OpenModalTeachers",
    groupCloseModalTeachers: "[group]CloseModalTeachers",
    groupOpenModal: "[group]OpenModal",
    groupCloseModal: "[group]CloseModal",
    groupShow: "[group]Show",
    groupTeacherSetActive: "[group]TeacherSetActive",
    groupShowListModules: "[group]ShowListModules",

    //Algebraix
    algebraixLogin: "[algebraix]:login",
    //users
    userShow: "[users]:userShow",
    userGetNextNumberEmployee: "[users]:getNextNumberEmployee",
    userList: "[users]:userList",
    userAdd: "[users]:userAdd",
    userEdit: "[users]:userEdit",
    userDelete: "[users]:userDelete",
    userSetActive: "[users]:userSetActive",
    userUnsetActive: "[users]:userUnsetActive",
    userOpenModal: "[users]:userOpenModal",
    userCloseModal: "[users]:userCloseModal",
    userOpenModalModules: "[users]:userOpenModalModules",
    userCloseModalModules: "[users]:userCloseModalModules",

    //auth Teachers
    teacherAuthLogin: "[authTeacher]:authLogin",
    teacherAuthRenew: "[authTeacher]:Renew",
    teacherAuthLogout: "[authTeacher]:Logout",
    teacherAuthCheckingFinish: "[authTeacher]:CheckingFinish",

    //auth Students
    studentAuthLogin: "[authStudent]:authLogin",
    studentAuthRenew: "[authStudent]:Renew",
    studentAuthLogout: "[authStudent]:Logout",
    studentAuthCheckingFinish: "[authStudent]:CheckingFinish",

    //Teachers
    teacherShow: "[teacher]:teacherShow",
    teacherList: "[teacher]:teacherList",
    teacherOpenModal: "[teacher]:teacherOpenModal",
    teacherCloseModal: "[teacher]:teacherCloseModal",
    teacherSetActive: "[teacher]: teacherSetActive",
    teacherUnsetActive: "[teacher]: teacherUnsetActive",
    teacherOpenContract: "[teacher]: teacherOpenContract",
    teacherCloseContract: "[teacher]: teacherCloseContract",
    teacherGroups: "[teacher]: teacherGroups",
    teacherSubjects: "[teacher]: teacherSubjects",
    teacherSubjectActive: "[teacher]: teacherSubjectActive",

    //Schedules
    calendarOpenModal: "[calendar]:calendarOpenModal",
    calendarCloseModal: "[calendar]:calendarCloseModal",
    calendarShow: "[calendar]:calendarShow",

    //Teachers Tabuladores
    teacherOpenTabulators: "[teacher]: teacherOpenTabulators",
    teacherCloseTabulators: "[teacher]: teacherCloseTabulators",
    teacherShowTabulators: "[teacher]:teacherShowTabulators",

    //Payrolls
    payrollShow: "[payroll]:payrollShow",
    payrollSetActive: "[payroll]:payrollSetActive",
    payrollUnsetActive: "[payroll]:payrollUnsetActive",
    payrollOpenModal: "[payroll]:payrollOpenModal",
    payrollCloseModal: "[payroll]:payrollCloseModal",
    payrollJustifyDay: "[payroll]:payrollJustifyDay",
    payrollJustifyDayOpenModal: "[payroll]:payrollJustifyDayOpenModal",
    payrollJustifyDayCloseModal: "[payroll]:payrollJustifyDayCloseModal",
    payrollTeacherShow: "[payrollTeacher]:payrollTeacherShow",
    payrollTeacherSetActive: "[payrollTeacher]:payrollTeacherSetActive",
    payrollTeacherUnsetActive: "[payrollTeacher]:payrollTeacherUnsetActive",
    payrollTeacherDetailShow: "[payrollTeacherDetail]:payrollTeacherDetailShow",
    payrollTeacherDetailSetActive: "[payrollTeacherDetail]:pdtSetActive",
    payrollTeacherDetailUnsetActive: "[payrollTeacher]:ptdUnsetActive",
    payrollTeacherShowWithOutPunches:
        "[payrollTeacher]:payrollTeacherShowWithOutPunches",

    justificationOpenModal: "[justification]:justificationOpenModal",
    justificationCloseModal: "[justification]:justificationCloseModal",
    justificationOpenAddTeacher: "[justification]:justificationOpenAddTeacher",
    justificationCloseAddTeacher:
        "[justification]:justificationCloseAddTeacher",

    //Cuts Payrolls
    cutspayrollShow: "[payroll]:cutspayrollShow",
    cutspayrollList: "[payroll]:cutspayrollList",
    cutspayrollOpenModal: "[payroll]:cutspayrollOpenModal",
    cutspayrollCloseModal: "[payroll]:cutspayrollCloseModal",

    //plans_and_study_programs
    practiceProfessionalShow: "[plansAndStudyPrograms]practiceProfessionalShow",
    PPOpenModal: "[plansAndStudyPrograms]PPOpenModal",
    PPCloseModal: "[plansAndStudyPrograms]PPCloseModal",
    PartnershipsOpenModal: "[plansAndStudyPrograms]PartnershipsOpenModal",
    PartnershipsCloseModal: "[plansAndStudyPrograms]PartnershipsCloseModal",
    PPSetActive: "[plansAndStudyPrograms]PPSetActive",
    PPUnsetActive: "[plansAndStudyPrograms]PPUnsetActive",
    PartnershipsSetActive: "[plansAndStudyPrograms]PartnershipsSetActive",
    PartnershipsUnsetActive: "[plansAndStudyPrograms]PartnershipsUnsetActive",
    socialServiceShow: "[plansAndStudyPrograms]socialServiceShow",
    partnertshipsShow: "[plansAndStudyPrograms]partnertshipsShow",
    newPractice: "[plansAndStudyPrograms]newPractice",
    newSocialService: "[plansAndStudyPrograms]newSocialService",
    setSocialServiceActive: "[plansAndStudyProgram]setSocialServiceActive",
    unsetSocialServiceActive:
        "[plansAndStudyPrograms]unsetSocialServiceActivity",

    // TODO careers -- deprecated
    careersShow: "[plansAndStudyPrograms]careersShow",
    careersList: "[plansAndStudyPrograms]careersList",
    careerUnsetActive: "[plansAndStudyPrograms]careerUnsetActive",
    careerOpenModal: "[plansAndStudyPrograms]careerOpenModal",
    careerCloseModal: "[plansAndStudyPrograms]careerCloseModal",
    careerOpenModalUsers: "[plansAndStudyPrograms]careerOpenModalUsers",
    careerCloseModalUsers: "[plansAndStudyPrograms]careerCloseModalUsers",

    //employee
    employeeShow: "[employee]show",
    employeeOpenModalSchedules: "[employee]openModalSchedules",
    employeeCloseModalSchedules: "[employee]closeModalSchedules",
    employeeSetPunch: "[employee]:employeeSetPunch",
    employeeSetAllPunch: "[employee]:employeeSetAllPunch",
    employeeUnsetPunch: "[employee]:employeeUnsetPunch",

    //department
    departmentAll: "[department]all",
    departmentShow: "[department]show",
    departmentSetActive: "[department]active",
    departmentUnsetActive: "[department]unsetActive",
    departmentOpenForm: "[department]openForm",
    departmentCloseForm: "[department]closeForm",

    //configs
    setConfig: "[config]set",

    //Incidents
    incidentsOpenForm: "[incidents]openForm",
    incidentsCloseForm: "[incidents]closeForm",
    incidentsOpenIncident: "[incidents]openIncident",
    incidentsCloseIncident: "[incidents]closeIncident",
    incidentsSetDay: "[incidents]setDay",
    incidentsSetIncidentsByUser: "[incidents]setIncidentsByUser",
    incidentsShow: "[incidents]show",
    incidentsSetActive: "[incidents]setActive",

    //daysOff
    dayOffOpenModal: "[dayOff]openModal",
    dayOffCloseModal: "[dayOff]closeModal",
    dayOffShow: "[dayOff]show",
    dayOffCreate: "[dayOff]create",
    dayOffDelete: "[dayOff]delete",

    //schedule Employee and operators
    scheduleEmployeeSetActive: "[scheduleEmployee]setActive",
    scheduleEmployeeUnsetActive: "[scheduleEmployee]unsetActive",
    setGradesByGroup: "[scheduleEmployee]setGradesByGroup",
    UnSetGradesByGroup: "[scheduleEmployee]UnSetGradesByGroup",
    setClassLoadReport: "[scheduleEmployee]setClassLoadReport",

    // Manuals
    manualShow: "[manuals]manualShow",
    manualList: "[manuals]manualList",
    manualsetActive: "[manuals]manualSetActive",
    manualOpenForm: "[manuals]manualOpenForm",
    manualCloseForm: "[manuals]manualCloseForm",
    manualOpenFormUsers: "[manuals]manualOpenFormUsers",
    manualCloseFormUsers: "[manuals]manualCloseFormUsers",
    // Formats
    formatShow: "[formats]formatShow",
    formatList: "[formats]formatList",
    formatsetActive: "[formats]formatSetActive",
    formatOpenForm: "[formats]formatOpenForm",
    formatCloseForm: "[formats]formatCloseForm",
    formatOpenFormUsers: "[formats]formatOpenFormUsers",
    formatCloseFormUsers: "[formats]formatCloseFormUsers",

    //tickets
    openFormTicketTI: "[ticketTI]open",
    openDetailTicketTI: "[ticketTI]openDetail",
    closeDetailTicketTI: "[ticketTI]closeDetail",
    closeFormTicketTI: "[ticketTI]close",
    setActiveTicketTI: "[ticketTI]active",
    unsetActiveTicketTI: "[ticketTI]unsetActive",
    showTicketTI: "[ticketTI]show",
    deleteTicketTI: "[ticketTI]delete",
    editTicketTI: "[ticketTI]edit",
    sendMessageTicketTI: "[ticketTI]sendMessageTicketTI",
    findTicketTI: "[ticketTI]findTicketTI",
    reloadChatTicketTI: "[ticketTI]reloadChatTicketTI",
    clearChatTI: "[ticketTI]clearChat",
    setChatUser: "[ticketTI]setChatUser",

    //Events Rooms
    eventsOpenForm: "[events]openForm",
    eventsCloseForm: "[events]closeForm",
    eventsOpenEvent: "[events]openEvent",
    eventsCloseEvent: "[events]closeEvent",
    eventsSetDay: "[events]setDay",
    eventsSetRoom: "[events]setRoom",
    eventsGet: "[events]get",
    eventsShow: "[events]show",

    //V2 payroll teachers

    //Curricula
    curriculaShow: "[Curricula]show",
    curriculaShowGroups: "[Curricula]showGroups",
    curriculaList: "[Curricula]list",
    curriculaSetActive: "[Curricula]setActive",
    curriculaOpenReportModal: "[Curricula]openReportModal",
    curriculaCloseReportModal: "[Curricula]closeReportModal",
    curriculaUnsetActive: "[Curricula]unsetActive",
    curriculaOpenForm: "[Curricula]openForm",
    curriculaCloseForm: "[Curricula]closeForm",
    curriculaOpenModalUsers: "[Curricula]openModalUsers",
    curriculaCloseModalUsers: "[Curricula]closeModalUsers",
    curriculaOpenModalGroups: "[Curricula]openModalGroups",
    curriculaCloseModalGroups: "[Curricula]closeModalGroups",
    curriculaSetTree: "[Curricula]setTree",
    curriculaClean: "[Curricula]clean",

    //Tabuladores
    tabulatorOpen: "[tabulator]: tabulatorOpen",
    tabulatorClose: "[tabulator]: tabulatorClose",
    tabulatorShow: "[tabulator]:tabulatorShow",
    tabulatorSetActive: "[tabulator]setActive",
    tabulatorUnsetActive: "[tabulator]unsetActive",
    //School Subject
    schoolSubjectShow: "[schoolSubject]show",
    schoolSubjectList: "[schoolSubject]list",
    schoolSubjectListClear: "[schoolSubject]listClear",
    schoolSubjectListTwo: "[schoolSubject]list_two",
    schoolSubjectListByStudent: "[schoolSubject]listByStudent",
    schoolSubjectSetActive: "[schoolSubject]setActive",
    schoolSubjectUnsetActive: "[schoolSubject]unsetActive",
    schoolSubjectOpenForm: "[schoolSubject]openForm",
    schoolSubjectCloseForm: "[schoolSubject]closeForm",
    setTeachersCycles: "[schoolSubject]:setTeachersCycles",

    //cycles
    CycleOpenModal: "[Cycles]:OpenModal",
    CycleCloseModal: "[Cycles]:CloseModal",
    CycleSetActive: "[Cycles]:CycleSetActive",
    CycleUnsetActive: "[Cycles]:CycleUnsetActive",
    CyclesShow: "[Cycles]:Show",
    CyclesList: "[Cycles]:List",
    CyclesByUsers: "[Cycles]:CyclesByUsers",
    CycleEmpty: "[Cycles]:Empty",

    //Coordination
    coordinationList: "[Coordination]:list",
    coordinationShow: "[Coordination]:show",
    coordinationsOpenModal: "[Coordination]:OpenModalCoordination",
    coordinationsCloseModal: "[Coordination]:CloseModalCoordination",
    coordinationsOpenReportModal: "[Coordination]:OpenReportModalCoordination",
    coordinationsCloseReportModal:
        "[Coordination]:CloseReportModalCoordination",
    coordinationSetActive: "[Coordination]:SetActive",
    coordinationUnsetActive: "[Cordination]:SetActive",
    coordinationSetAttendanceReport: "[Cordination]:SetAttendanceReport",

    //Schedules Teachers
    schedulesTeacherOpenModal: "[schedulesTeacher]:OpenModal",
    schedulesTeacherCloseModal: "[schedulesTeacher]:CloseModal",
    schedulesTeacherShow: "[schedulesTeacher]:Show",
    schedulesTeacherClasses: "[schedulesTeacher]:Classes",

    //Delay
    DelayShowList: "[delay]:ShowList",

    //PAYROLL V2
    // payrollV2: "[payrollV2]: payroll",
    payrollV2GetPunchesByDay: "[payrollV2]: GetPunchesByDay",
    payrollV2GetByTeacher: "[payrollV2]:GetByTeacher",
    payrollV2OpenModal: "[payrollV2]:OpenModal",
    payrollV2CloseModal: "[payrollV2]:CloseModal",
    payrollV2OpenModalPunches: "[payrollV2]:OpenModalPunches",
    payrollV2CloseModalPunches: "[payrollV2]:CloseModalPunches",
    payrollV2Clear: "[payrollV2]:clear",

    //JUSTIFIES

    justifyShowJustifies: "[justify]:ShowJustifies",
    justifySetActive: "[justify]:SetActive",
    justifyOpenModal: "[justify]:OpenModal",
    justifyCloseModalMassive: "[justify]:CloseModalMassive",
    justifyOpenModalMassive: "[justify]:OpenModalMassive",
    justifyCloseModal: "[justify]:CloseModal",
    justifyShowJustifies: "[justify]:ShowJustifies",

    //EAD
    eadSetActive: "[ead]:setActive",
    eadUnsetActive: "[ead]:unsetActive",
    eadOpenModal: "[ead]:openModal",
    eadCloseModal: "[ead]:CloseModal",
    eadShow: "[ead]:Show",
    eadSetTabulators: "[ead]:setTabulators",

    eadOpenModalCopyPayroll: "[ead]:openModalCopyPayroll",
    eadCloseModalCopyPayroll: "[ead]:CloseModalCopyPayroll",

    //PUNCHES TEACHERS
    punchesOpenModal: "[punches]:OpenModal",
    punchesCloseModal: "[punches]:CloseModal",

    //Modulos Teachers
    modulesTeacherShow: "[module]:TeacherShow",
    moduleTeacherList: "[module]:TeacherList",
    moduleTeacherSetActive: "[module]:TeacherSetActive",
    moduleTeacherOpenForm: "[module]:TeacherOpenForm",
    moduleTeacherCloseForm: "[module]:TeacherCloseForm",

    //organization
    organizationOpenModal: "[organization]:open",
    organizationCloseModal: "[organization]:close",
    organizationSetTreeData: "[organization]:setTree",
    organizationEditModeToggle: "[organization]:editModeToggle",
    // organizationShow:"[organization]:show",

    //Students
    studentsShow: "[Student]show",
    showStudentOnly: "[Student]showStudentOnly",
    studentsActive: "[Student]studentsSetActive",
    studentsUnsetActive: "[Student]studentsUnsetActive",
    studentsOpenForm: "[Student]studentsOpenForm",
    studentsCloseForm: "[Student]studentsCloseForm",
    studentsGroupsList: "[Student]studentsGroupsList",
    studentsOpenFormTags: "[Student]studentsOpenFormTags",
    studentsCloseFormTags: "[Student]studentsCloseFormTags",
    studentsTags: "[Student]studentsSetTags",
    studentCyclesForceView: "[Student]studentCyclesForceView",
    studentCycleChange: "[Student]studentCycleChange",
    studentGroups: "[Student]studentGroups",
    studentClearGroups: "[Student]studentClearGroups",
    studentsSchoolsOfOrigin: "[Student]studentsSchoolsOfOrigin",
    studentsCondensedSchoolsOfOrigin:
        "[Student]studentsCondensedSchoolsOfOrigin",
    studentsBlock: "[Student]studentsBlock",

    studentsSetCycleStudentsReport: "[Student]setCycleStudentsReport",
    studentsClearCycleStudentsReport: "[Student]clearCycleStudentsReport",
    studentsSetActiveCycle: "[Student]setActiveCycle",

    //Algebraix
    studentsAlgebraixShow: "[StudentAlgebraix]show",

    //ElectronicTitle
    validateElectronicTitle: "[electronicTitle]validateElectronicTitle",
    showElectronicTitle: "[electronicTitle]showElectronicTitle",

    //Seccion de compras(shopping)

    //providers
    formProviders: "[shopping]:formProviders",
    showProviders: "[shopping]:showProviders",
    showListProviders: "[shopping]:showListProviders",
    providersOpenModal: "[shopping]:providersOpenModal",
    providersCloseModal: "[shopping]:providersCloseModal",

    //Purchases
    purchaseSetActive: "[shopping]:purchaseSetActive",
    purchaseUnsetActive: "[shopping]:purchaseUnsetActive",
    purchasesOpenModal: "[shopping]:purchasesOpenModal",
    purchasesCloseModal: "[shopping]:purchasesCloseModal",
    purchasesSetCurrentForm: "[shopping]:setCurrentForm",

    purchasesOpenConfigModal: "[shopping]:purchasesOpenConfigModal",
    purchasesCloseConfigModal: "[shopping]:purchasesCloseConfigModal",

    formPurchases: "[shopping]:formPurchases",
    showPurchases: "[shopping]:showPurchases",
    showPurchasesFilesHistory: "[shopping]:showPurchasesFilesHistory",

    //Budgets
    showBudgets: "[shopping]:showBudgets",
    showBudgetsHistory: "[shopping]:showBudgetsHistory",
    budgetsOpenModal: "[shopping]:budgetsOpenModal",
    budgetsCloseModal: "[shopping]:budgetsCloseModal",
    showListBudgets: "[shopping]:showListBudgets",

    //Fin seccion de compras(shopping)

    //BBajio
    startSaveHashBB: "[bbajio]:saveHash",
    startStatusPaymentBB: "[bbajio]:statusPaymentBB",
    startClearDataBB: "[bbajio]:clearData",

    //BBVA
    startShowBBVA: "[bbva]:showBBVA",

    //PayU
    startSavePayu: "[PayU]:save",
    startClearDataPayu: "[PayU]:clean",
    startStatusPaymentPayu: "[PayU]:status",

    //Academic Groups
    showAcademicGroups: "[academicGroups]:show",
    academicGroupsOpenModal: "[academicGroups]:OpenModal",
    academicGroupsCloseModal: "[academicGroups]:CloseModal",
    academicGroupsSetActive: "[academicGroups]: AcademicGroupsSetActive",
    academicGroupsUnsetActive: "[academicGroups]: academicGroupsUnsetActive",
    studentsByGroupSetActive: "[academicGroups]: studentsByGroupSetActive",
    studentsByGroupUnSetActive: "[academicGroups]: studentsByGroupUnSetActive",
    academicGroupSetValues: "[academicGroups]: academicGroupSetValues",
    academicGroupsGetByCurricula:
        "[academicGroups]: academicGroupsGetByCurricula",
    academicGroupsSetCalendar: "[academicGroups]:academicGroupsSetCalendar",
    academicGroupsSetCycleSelected:
        "[academicGroups]:academicGroupsSetCycleSelected",
    academicGroupBlockGroup: "[academicGroups]:academicGroupBlockGroup",
    academicGroupCompactedList: "[academicGroups]:academicGroupCompactedList",
    academicGroupCompactedListClear:
        "[academicGroups]:academicGroupCompactedListClear",

    //Infrastructure
    buildingShow: "[building]:show",
    buildingList: "[building]:list",
    buildingSetActive: "[building]:setActive",
    buildingUnsetActive: "[building]:unsetActive",
    buildingOpenModal: "[building]:OpenModal",
    buildingCloseModal: "[building]:CloseModal",
    buildingSetClassSchedulesReport: "[building]:setClassSchedulesReport",

    classroomShow: "[classroom]:show",
    classroomList: "[classroom]:list",
    classroomList2: "[classroom]:list2",
    classroomClearList2: "[classroom]:clearList2",
    classroomSetActive: "[classroom]:setActive",
    classroomUnsetActive: "[classroom]:unsetActive",
    classroomOpenModal: "[classroom]:OpenModal",
    classroomCloseModal: "[classroom]:CloseModal",
    classroomSetCalendar: "[classroom]:setCalendar",

    //Curricula Tabulators
    curriculaTabulatorsShow: "[curriculaTabulators]show",
    curriculaTabulatorsList: "[curriculaTabulators]list",
    curriculaTabulatorsSetActive: "[curriculaTabulators]setActive",
    curriculaTabulatorsUnsetActive: "[curriculaTabulators]unsetActive",
    curriculaTabulatorsOpenModal: "[curriculaTabulators]OpenModal",
    curriculaTabulatorsCloseModal: "[curriculaTabulators]CloseModal",
    curriculaTabulatorsOpenCycleModal: "[curriculaTabulators]OpenCycleModal",
    curriculaTabulatorsCloseCycleModal: "[curriculaTabulators]CloseCycleModal",

    //calendarSchedule
    calendarScheduleSetCalendar: "[calendarSchedule]:setCalendar",
    calendarScheduleSetCalendars: "[calendarSchedule]:setCalendars",
    calendarScheduleSetCleanCalendar: "[calendarSchedule]:setCleanCalendar",
    calendarScheduleSetSchedulesList: "[calendarSchedule]:setSchedulesList",
    calendarScheduleSetSchedulesAcademicGroupId:
        "[calendarSchedule]:SetSchedulesAcademicGroupId",
    calendarScheduleOpenModal: "[calendarSchedule]:OpenModal",
    calendarScheduleCloseModal: "[calendarSchedule]:CloseModal",
    calendarScheduleSetActive: "[calendarSchedule]:SetActive",
    calendarScheduleUnsetActive: "[calendarSchedule]:UnsetActive",
    calendarScheduleEnableForm: "[calendarSchedule]:EnableForm",
    calendarScheduleDisableForm: "[calendarSchedule]:DisabledForm",
    calendarScheduleSetSlot: "[calendarSchedule]:setSlot",
    calendarScheduleSetBuilding: "[calendarSchedule]:setBuilding",
    calendarScheduleClearBuilding: "[calendarSchedule]:clearBuilding",
    calendarScheduleSetClassroom: "[calendarSchedule]:setClassroom",
    calendarScheduleClearClassroom: "[calendarSchedule]:clearClassroom",
    calendarScheduleOpenModalScholarSubjectsByTeacher:
        "[calendarSchedule]:calendarScheduleOpenModalScholarSubjectsByTeacher",
    calendarScheduleCloseModalScholarSubjectsByTeacher:
        "[calendarSchedule]:calendarScheduleCloseModalScholarSubjectsByTeacher",
    calendarSetCompactedSchedulesByCycle:
        "[calendarSchedule]:calendarSetCompactedSchedulesByCycle",
    //*Enrollments schedule form
    calendarSetEnrollments: "[calendarSchedule]:calendarSetEnrollments",
    calendarSetEmptyEnrollments:
        "[calendarSchedule]:calendarSetEmptyEnrollments",
    calendarSetCalendarDisponibility:
        "[calendarSchedule]:calendarSetCalendarDisponibility",
    calendarClearCalendarDisponibility:
        "[calendarSchedule]:calendarClearCalendarDisponibility",

    //*GlobalReducer
    setCountries: "[global]countries",
    setStatesBirth: "[global]statesBirth",
    setStatesBirthTutor: "[global]statesBirthTutor",
    setStatesResidence: "[global]statesResidence",
};
